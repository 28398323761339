@charset "utf-8";

.base-wrap {
  width: 100%;
  position: relative;
}
.base-main {
  margin-bottom: 128px;
}
.base-inner {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 16px;
}
.base-headline {
  margin-top: calc(48px + 16px);
  margin-bottom: 48px;
}
.base-headline p {
  font-family: 'Raleway' , "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0.1em;
  text-align: center;
}
.base-singleTop {

}
.base-column {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.base-column__main__section {

}
.base-column__main__section + .base-column__main__section {
  margin-top: 64px;
}
.base-column__ttl {
  width: 100%;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.base-column__ttl::before,
.base-column__ttl::after {
  content: "";
  width: 160px;
  height: 1px;
  background: #DBD4CE;
  flex-shrink: 0;
}

.base-column__ttl h2 {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
}
.base-column__ttl p {
  font-size: 14px;
  font-family: 'Raleway';
  text-align: center;
}
.base-column__spFixed { display: none; }
@media screen and (max-width:768px) {
  .base-headline {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  .base-singleTop {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  .base-column {
    margin-top: 16px;
    display: block;
  }
  .base-column__main__section + .base-column__main__section {
    margin-top: 32px;
  }
  .base-column__ttl {
    margin-bottom: 16px;
  }
  .base-column__ttl::before,
  .base-column__ttl::after {
    width: 32px;
  }
  .base-column__ttl h2 {
    font-size: 20px;
  }
  .base-column__ttl p {
    font-size: 12px;
  }
  .base-column__spFixed {
    width: 100%;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    bottom: 0;
    z-index: 50;
  }
  .base-column__spFixed::before {
    content: "";
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, rgba(249,249,249,0) 0%,rgba(249,249,249,1) 100%);
    position: absolute;
    top: 0;
    left: 0;
  }
  .base-column__spFixed::after {
    content: "";
    width: 100%;
    height: 50%;
    background: #f9f9f9;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .base-column__spFixed__btn {
    width: 280px;
    height: 48px;
    font-size: 18px;
    font-weight: bold;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
  }
}

/*------------------------------------------------------------------------------
utility
------------------------------------------------------------------------------*/
.base-padding__8-8 { padding: 8px; }
.base-padding__8-16 { padding: 8px 16px; }
.base-padding__16-8 { padding: 16px 8px; }
.base-padding-16 { padding: 16px; }
.base-padding-16-32 { padding: 16px 32px; }
.base-padding-32-16 { padding: 32 16px; }

.base-marginTop-8 { margin-top: 8px; }
.base-marginTop-16 { margin-top: 16px; }
.base-marginTop-32 { margin-top: 32px; }

.base-marginBottom-8 { margin-bottom: 8px; }
.base-marginBottom-16 { margin-bottom: 16px; }
.base-marginBottom-32 { margin-bottom: 32px; }


/* from sample.css */

/*------------------------------------------------------------------------------
header
------------------------------------------------------------------------------*/
.header {
  width: 100%;
  height: 80px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  position: relative;
  z-index: 50;
}
.header__inner {
  height: calc(100% - 32px);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.header__desc {
  min-height: 32px;
  padding-top: 8px;
}
.header__desc p {
  font-size: 12px;
  font-weight: bold;
  display: inline;
}
.header__desc p::after {
  content: "|";
  padding-left: 4px;
  margin-right: 4px;
  display: inline;
}
.header__desc h1 {
  font-size: 12px;
  font-weight: normal;
  display: inline;
}
.header__main {

}
.header__main__logo {
  display: block;
}
.header__main__logo img {
  height: 32px;
}
.header__lang {
  width: 128px;
  height: 32px;
  border-radius: 2px;
  padding-left: 16px;
  margin-top: -8px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  position: relative;
}
.header__lang img {
  width: 20px;
  height: 20px;
}
.header__lang select {
  width: calc(100% - 32px);
  height: 100%;
  padding-left: 8px;
  cursor: pointer;
}
.header__lang option {
  color: #333;
}
@media screen and (max-width:768px) {
  .header { height: 64px; }
  .header__desc {
    width: 100%;
    height: 32px;
    background: #fff;
    padding: 0 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: absolute;
    top: 64px;
    left: 0;
    white-space: nowrap;
    overflow-x: scroll;
  }
  .header__inner {
    width: 100%;
    height: 64px;
    box-shadow: 0 2px 8px rgba(0,0,0,0.1);
    padding: 0 8px;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
  }
  .header__lang {
    margin-top: 0;
  }
}


/*------------------------------------------------------------------------------
footer
------------------------------------------------------------------------------*/
.footer {

}
.footer__inner {
  padding: 32px 0;
  display: flex;
  align-items: center;
}
.footer__info {
  opacity: 0.8;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.footer__info ul {
  margin-right: 32px;
}
.footer__info li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.footer__info li + li {
  margin-top: 16px;
}
.footer__info li img {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  margin-right: 4px;
}
.footer__menu {
  margin-left: auto;
}
.footer__menu ul {
  text-decoration: underline;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
}
.footer__menu li {
  margin-left: 32px;
}
.footer__menu li a:hover {
  opacity: 0.8;
}
.footer__copy {
  opacity: 0.8;
  border-top-width: 1px;
  border-top-style: solid;
  text-align: center;
  padding: 32px 0;
}
@media screen and (max-width:768px) {
  .footer__inner {
    padding: 32px 16px;
    display: block;
  }
  .footer__inner ul + ul {
    margin-top: 16px;
  }
  .footer__info {
    display: block;
  }
  .footer__menu {
    margin-top: 32px;
    margin-left: 0;
  }
  .footer__menu ul {
    justify-content: flex-start;
  }
  .footer__menu li {
    margin-left: 0;
    margin-right: 32px;
  }
  .footer__copy {
    width: calc(100% - 32px);
    margin: 0 auto;
  }
}


/*------------------------------------------------------------------------------
mainvisual
------------------------------------------------------------------------------*/
.mainvisual {
  width: 100%;
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  position: relative;
}
.mainvisual::before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.mainvisual__pic {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.mainvisual__desc {
  position: relative;
  z-index: 2;
}
.mainvisual__desc h2 {
  max-width: 580px;
  font-family: 'Raleway', "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-size: 40px;
  letter-spacing: 0.16em;
  word-break: break-all;
}
.mainvisual__desc span {
  width: 240px;
  height: 1px;
  display: block;
  margin-top: 16px;
  margin-bottom: 24px;
}
.mainvisual__desc p {
  max-width: 580px;
  font-size: 14px;
  letter-spacing: 0.1em;
  word-break: break-all;
}
.mainvisual__scroll {
  width: 64px;
  font-family: 'Raleway', "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  position: absolute;
  left: calc(50% - 32px);
  bottom: -48px;
  z-index: 2;
}
.mainvisual__scroll span {
  width: 1px;
  height: 96px;
  background: #2E1F12;
  margin: 4px auto 0 auto;
  overflow: hidden;
  display: block;
  position: relative;
}
.mainvisual__scroll span::after {
  content: "";
  width: 1px;
  height: 32px;
  background: #fff;
  display: block;
  position: absolute;
  left: 0;
  animation: scroll 1.6s linear infinite;
}
@keyframes scroll {
  0% { top: -32px; }
  100% { top: 100%; }
}
@media screen and (max-width:768px) {
  .mainvisual {
    height: 360px;
    padding-bottom: 16px;
    align-items: flex-end;
  }
  .mainvisual__desc {

  }
  .mainvisual__desc h2 {
    font-size: 24px;
  }
  .mainvisual__desc span {
    width: 120px;
    margin-top: 12px;
    margin-bottom: 16px;
  }
  .mainvisual__desc p {
    font-size: 12px;
  }
  .mainvisual__scroll { display: none; }
}


/*------------------------------------------------------------------------------
item
------------------------------------------------------------------------------*/
.item {

}
.item__li {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.item__li + .item__li { margin-top: 48px; }
.item__li__pic {
  width: 600px;
  height: 400px;
  flex-shrink: 0;
  display: block;
}
.item__li__pic:hover {
  opacity: 0.8;
}
.item__li:nth-child(odd) .item__li__pic { order: 0; margin-right: 32px; }
.item__li:nth-child(odd) .item__li__info { order: 1; }
.item__li:nth-child(even) .item__li__pic { order: 1; margin-left: 32px; }
.item__li:nth-child(even) .item__li__info { order: 0; }
.item__li__pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item__li__info {

}
.item__li__info__ttl {
  font-size: 24px;
  text-overflow: ellipsis;
  overflow : hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-break: break-all;
}
.item__li__info__tag {
  margin-top: 16px;
  margin-bottom: -4px;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.item__li__info__tag p {
  font-size: 12px;
  line-height: 22px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 0 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  display: inline-block;
  word-break: break-all;
}
.item__li__info__desc {
  margin-top: 16px;
  font-size: 14px;
  color: #888;
  text-overflow: ellipsis;
  overflow : hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  word-break: break-all;
}
.item__li__info__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
}
.item__li__info__bottom__left {

}
.item__li__info__bottom__left__week {
  font-size: 14px;
}
.item__li__info__bottom__left__erea {
  font-size: 16px;
  font-weight: bold;
}
.item__li__info__bottom__price {
  font-size: 24px;
  font-weight: bold;
  text-align: right;
}
.item__li__info__btn {
  width: 240px;
  height: 40px;
  font-family: 'Raleway', "ヒラギノ角ゴ Pro", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.1em;
  border-radius: 2px;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.item__li__info__btn::after {
  content: "";
  width: 8px;
  height: 8px;
  border-top-width: 1px;
  border-right-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 4px);
  right: 16px;
}
.item__li__info__btn:hover {
  opacity: 0.8;
}
.base-info__frame__ac__header__btn {
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid;
  margin-left: auto;
}
.base-info__frame__ac__btn {
  font-size: 14px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px solid;
  margin: 0 auto 5px auto;
  padding: 6px 16px;
  margin: 0 auto 10px;
}
@media screen and (max-width:768px) {
  .item__li { display: block }
  .item__li__pic {
    width: 100%;
    height: 240px;
    flex-shrink: 0;
  }
  .item__li__pic:hover {
    opacity: 0.8;
  }
  .item__li:nth-child(odd) .item__li__pic { margin-right: 0; }
  .item__li:nth-child(even) .item__li__pic { margin-left: 0; }
  .item__li__info {
    display: flex;
    flex-direction: column;
  }
  .item__li__info > * { order: 2; }
  .item__li__info__ttl {
    font-size: 18px;
    -webkit-line-clamp: 2;
    margin-top: 8px;
    order: 0;
  }
  .item__li__info__tag {
    margin-top: 8px;
    order: 1;
  }
  .item__li__info__desc {
    margin-top: 8px;
  }
  .item__li__info__bottom {
    margin-top: 16px;
  }
  .item__li__info__btn {
    margin-left: auto;
    margin-right: auto;
  }
  .item__li__info__bottom__left__week {
    font-size: 12px;
  }
  .item__li__info__bottom__left__erea {
    font-size: 14px;
  }
  .item__li__info__bottom__price {
    font-size: 18px;
  }
}
@media screen and (max-width:768px) {

}


/*------------------------------------------------------------------------------
maininfo
------------------------------------------------------------------------------*/
.maininfo {
  margin-top: 32px;
}
.maininfo__ttl {
  font-size: 32px;
  margin-bottom: 8px;
}
.maininfo__tag {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

}
.maininfo__tag p {
  font-size: 12px;
  line-height: 22px;
  border-width: 1px;
  border-style: solid;
  border-radius: 2px;
  padding: 0 4px;
  margin-right: 4px;
  margin-bottom: 4px;
  display: inline-block;
  word-break: break-all;
}
@media screen and (max-width:768px) {
  .maininfo {
    padding: 0 16px;
    margin-top: 16px;
    order: 2;
  }
  .maininfo__ttl {
    font-size: 20px;
  }
}


/*------------------------------------------------------------------------------
pictures
------------------------------------------------------------------------------*/
.pictures {
  margin-top: 32px;
  position: relative;
}
.pictures__main {
  position: relative;
}
.pictures__main__photo {
  height: 460px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}
.pictures__main__photo li {
  position: absolute;
}
.pictures__main__photo li:nth-child(1) {
  width: 640px;
  height: 100%;
  top: 0;
  left: 0;
}
.pictures__main__photo li:nth-child(2) {
  width: calc((100% - 640px - 16px) / 2);
  height: calc((100% - 8px) / 2);
  top: 0;
  left: calc(640px + 8px);
}
.pictures__main__photo li:nth-child(3) {
  width: calc((100% - 640px - 16px) / 2);
  height: calc((100% - 8px) / 2);
  top: 0;
  right: 0;
}
.pictures__main__photo li:nth-child(4) {
  width: calc((100% - 640px - 16px) / 2);
  height: calc((100% - 8px) / 2);
  bottom: 0;
  left: calc(640px + 8px);
}
.pictures__main__photo li:nth-child(5) {
  width: calc((100% - 640px - 16px) / 2);
  height: calc((100% - 8px) / 2);
  bottom: 0;
  right: 0;
}
.pictures__main__photo li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.pictures__main__more {
  width: 160px;
  height: 40px;
  background: rgba(255,255,255,0.9);
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 16px;
  right: 16px;
}
.pictures__main__more:hover {
  background: rgba(255,255,255,1);
}
.pictures__main__more__ic {
  width: 13px;
  height: 13px;
  position: relative;
}
.pictures__main__more__ic span {
  content: "";
  width: 6px;
  height: 6px;
  position: absolute;
  display: block;
}
.pictures__main__more__ic span:nth-child(1) { top: 0; left: 0; }
.pictures__main__more__ic span:nth-child(2) { top: 0; right: 0; }
.pictures__main__more__ic span:nth-child(3) { bottom: 0; left: 0; }
.pictures__main__more__ic span:nth-child(4) { bottom: 0; right: 0; }
.pictures__main__more p { margin-left: 4px; }
.pictures__all {
  width: 100%;
  height: 100%;
  background: #000;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  transition: all 0.4s ease;
  transform: translateY(30%);
  display: none;
}
.pictures__all.is-active {
  transform: translateY(0);
  display: flex;
}
.pictures__all__close {
  width: 96px;
  height: 32px;
  border-radius: 4px;
  color: #fff;
  background: rgba(255,255,255,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 32px;
  left: 32px;
}
.pictures__all__close:hover {
  background: rgba(255,255,255,0.2);
}
.pictures__all__close p {
  width: 16px;
  height: 16px;
  margin-right: 4px;
  position: relative;
}
.pictures__all__close p::before,
.pictures__all__close p::after {
  content: "";
  width: 12px;
  height: 2px;
  background: #fff;
  display: block;
  position: absolute;
  top: calc(50% - 1px);
  left: 2px;
}
.pictures__all__close p::before { transform: rotate(45deg); }
.pictures__all__close p::after {  transform: rotate(-45deg); }
.pictures__all__photo {
  width: 100%;
  /* height: 560px; */
  height: calc(100vh * 560 / 768);
  position: relative;
}
.pictures__all__photo__main {
  width: 100%;
  height: 100%;
  position: relative;
}
.pictures__all__photo__main li {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}
.pictures__all__photo__main li.is-active {
  display: block;
}
.pictures__all__photo__main li img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.pictures__all__photo__btn {
  width: 56px;
  height: 56px;
  background: rgba(255,255,255,0.1);
  border-radius: 100%;
  position: absolute;
  top: calc(50% - 28px);
}
.pictures__all__photo__btn:hover {
  background: rgba(255,255,255,0.2);
}
.pictures__all__photo__btn::after {
  content: "";
  width: 16px;
  height: 16px;
  border-top: 2px solid #fff;
  border-left: 2px solid #fff;
  position: absolute;
  top: 18px;
  left: 22px;
  transform: rotate(-45deg);
  display: block;
}
.pictures__all__photo__btn.prev {
  left: 32px;
}
.pictures__all__photo__btn.next {
  right: 32px;
  transform: scale(-1, 1);
}
.pictures__all__thumbs {
  width: 960px;
  background: rgba(255,255,255,0.1);
  border-radius: 4px;
  padding: 8px;
  margin-top: 16px;
}
.pictures__all__thumbs__main {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.pictures__all__thumbs__main li {
  width: 100px;
  height: 60px;
  opacity: 0.5;
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
}
.pictures__all__thumbs__main li + li {
  margin-left: 8px;
}
.pictures__all__thumbs__main li.is-active { opacity: 1; }
.pictures__all__thumbs__main li.is-active::after {
  content: "";
  width: 100%;
  height: 100%;
  border: 3px solid #fff;
  display: block;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
}
.pictures__all__thumbs__main li img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
@media screen and (max-width:768px) {
  .pictures {
    order: 1;
  }
  .pictures__main__photo {
    height: 270px;
    border-radius: 0;
  }
  .pictures__main__photo li:nth-child(1) { width: 100%; }
  .pictures__main__photo li:nth-child(2),
  .pictures__main__photo li:nth-child(3),
  .pictures__main__photo li:nth-child(4),
  .pictures__main__photo li:nth-child(5) { display: none; }
  .pictures__main__more {
    width: 32px;
    height: 32px;
    border: none;
    bottom: 8px;
    right: 8px;
  }
  .pictures__main__more p { display: none; }
  .pictures__all__close {
    top: 16px;
    left: 8px;
  }
  .pictures__all__photo {
    height: 270px;
  }
  .pictures__all__photo__main li img {
    object-fit: cover;
  }
  .pictures__all__photo__btn {
    width: 32px;
    height: 32px;
    background: rgba(0,0,0,0.6);
    top: calc(50% - 16px);
  }
  .pictures__all__photo__btn:hover {
    background: rgba(0,0,0,0.6);
  }
  .pictures__all__photo__btn::after {
    width: 8px;
    height: 8px;
    top: 11px;
    left: 13px;
  }
  .pictures__all__photo__btn.prev { left: 8px; }
  .pictures__all__photo__btn.next { right: 8px; }
  .pictures__all__thumbs { width: 100%; }
}


/*------------------------------------------------------------------------------
introduction
------------------------------------------------------------------------------*/
.introduction {
  display: flex;
  flex-wrap: wrap;
}
.introduction__desc {
  width: 100%;
}
.introduction__price {
  width: calc((100% - 32px) / 2);
  margin-top: 32px;
  margin-right: 32px;
}
.introduction__price li {
  border-bottom: 1px solid #DBD4CE;
  padding: 8px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.introduction__price p:first-child {
  width: 240px;
  font-weight: bold;
  flex-shrink: 0;
}
.introduction__price p:last-child {

}
.introduction__info {
  width: calc((100% - 32px) / 2);
  margin-top: 32px;
  display: table;
  border-collapse: collapse;
}
.introduction__info li {
  display: table-row;
}
.introduction__info p {
  background: #fff;
  border: 1px solid #DBD4CE;
  padding: 8px;
  display: table-cell;
}
.introduction__info p:first-child {
  width: 120px;
  font-weight: bold;
}
.introduction__info p:last-child {

}
@media screen and (max-width:768px) {
  .introduction__price { width: 100%; margin-right: 0; }
  .introduction__info { width: 100%; }
}


/*------------------------------------------------------------------------------
promotion
------------------------------------------------------------------------------*/
.promotion {
  position: relative;
  overflow: hidden;
}
.promotion.is-close {
  max-height: 600px;
}
.promotion ul {

}
.promotion li {

}
.promotion > ul > li + li {
  margin-top: 16px;
}
.promotion__item {
  border-radius: 2px;
  background: #fff;
  padding: 16px 32px;
}
.promotion__item__ttl {
  width: 100%;
  border-bottom: 1px solid #DBD4CE;
  padding-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.promotion__item__ttl__pic {
  text-align: center;
  margin-right: 8px;
  flex-shrink: 0;
}
.promotion__item__ttl__pic img {
  width: 40px;
  height: 40px;
}
.promotion__item__ttl__pic p {
  font-size: 12px;
  font-weight: bold;
  color: #BB0000;
}
.promotion__item__ttl__txt {
  font-size: 18px;
  font-weight: bold;
}
.promotion__item__desc {
  margin-top: 8px;
}
.promotion__item__date {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.promotion__item__date p {
  font-weight: bold;
  margin-right: 16px;
  margin-top: 8px;
}
.promotion__item__price {

}
.promotion__item__price__item {
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.promotion__item__price__item + .promotion__item__price__item {
  border-top: 1px solid #DBD4CE;
}
.promotion__item__price__item__ttl {
  font-size: 16px;
  font-weight: bold;
}
.promotion__item__price__item__right {

}
.promotion__item__price__item__right > div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.promotion__item__price__item__right__label {
  width: 72px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  margin-right: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.promotion__item__price__item__right__discount {
  font-size: 18px;
  font-weight: bold;
}
.promotion__item__price__item__right__normal {
  font-size: 14px;
  color: #888;
  text-decoration: line-through;
  text-align: right;
}
.promotion__more {
  width: 100%;
  height: 48px;
  background: linear-gradient(to bottom, rgba(249,249,249,0) 0%,rgba(249,249,249,1) 100%);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  left: 0;
  bottom: 0;
}
.promotion__more.is-close {
  position: absolute;
}
.promotion__more a {
  width: 160px;
  height: 32px;
  font-size: 14px;
  font-weight: bold;
  border-width: 1px;
  border-style: solid;
  border-radius: 4px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width:768px) {
  .promotion__item { padding: 8px; }
  .promotion__item__ttl { display: block; }
  .promotion__item__ttl__txt { font-size: 14px; margin-top: 8px; }
  .promotion__item__price__item { padding: 16px 0; }
  .promotion__item__price__item__right > div { display: block; }
  .promotion__item__price__item__right__label { margin-left: auto; margin-right: 0; }
}


/*------------------------------------------------------------------------------
highlight
------------------------------------------------------------------------------*/
.highlight {

}
.highlight__list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.highlight__list__item {
  width: calc((100% - 32px) / 3);
  border-radius: 2px;
  background: #fff;
  padding: 0 8px 16px 8px;
  margin-top: 24px;
}
.highlight__list__item__point {
  width: 64px;
  height: 48px;
  border-radius: 2px;
  text-align: center;
  font-weight: bold;
  margin: -24px auto 16px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.highlight__list__item__point p:first-child {
  font-size: 12px;
  line-height: 12px;
  margin-top: 4px;
}
.highlight__list__item__point p:last-child {
  font-size: 20px;
  line-height: 20px;
}
.highlight__list__item__desc {

}
@media screen and (max-width:768px) {
  .highlight__list__item { width: 100%; }
  .highlight__list__item + .highlight__list__item { margin-top: 40px; }
}


/*------------------------------------------------------------------------------
overview
------------------------------------------------------------------------------*/
.overview {

}
.overview__tab {
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.overview__tab li {
  width: calc((100% - 16px) / 3);
  height: 40px;
  background: #fff;
  border-radius: 2px;
  margin-right: 8px;
}
.overview__tab li.is-active {
  color: #fff;
}
.overview__tab li:last-child { margin-right: 0; }
.overview__tab li a {
  width: 100%;
  height: 100%;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.overview__tab li a::after {
  content: "";
  width: 8px;
  height: 8px;
  border-right: 1px solid #2E1F12;
  border-bottom: 1px solid #2E1F12;
  transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 6px);
  right: 12px;
}
.overview__tab li.is-active a::after {
  border-right: 1px solid #fff;
  border-bottom: 1px solid #fff;
}
.overview__content {

}
.overview__content__item {
  width: 100%;
  background: #fff;
  border-radius: 2px;
  padding: 16px 16px 32px 16px;
  display: none;
}
.overview__content__item.is-active {
  display: block;
}
.overview__content__item__select {
  width: 300px;
  height: 32px;
  border: 1px solid #DBD4CE;
  border-radius: 2px;
  margin-bottom: 16px;
  display: block;
  position: relative;
}
.overview__content__item__select select {
  width: 100%;
  height: 100%;
  text-indent: 8px;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}
.overview__content__item__select::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 4px 0 4px;
  border-color: #2E1F12 transparent transparent transparent;
  position: absolute;
  top: calc(50% - 3px);
  right: 8px;
}
.overview__content__item__scheds {

}
.overview__content__item__scheds__item {
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.overview__content__item__scheds__item + .overview__content__item__scheds__item {
  margin-top: 16px;
}
.overview__content__item__scheds__item__time {
  width: 88px;
  text-align: right;
  flex-shrink: 0;
  position: relative;
}
.overview__content__item__scheds__item__point {
  margin: 4px 32px 0 32px;
  flex-shrink: 0;
  position: relative;
}
.overview__content__item__scheds__item__point p:nth-child(1) {
  width: 10px;
  height: 10px;
  border-radius: 100%;
}
.overview__content__item__scheds__item__point p:nth-child(2) {
  width: 2px;
  height: calc(100% + 16px);
  position: absolute;
  top: 5px;
  left: calc(50% - 1px);
}
.overview__content__item__scheds__item:last-child > .overview__content__item__scheds__item__point p:nth-child(2) {
  display: none;
}
.overview__content__item__scheds__item__info {

}
.overview__content__item__scheds__item__info__ttl {
  font-weight: bold;
}
a.overview__content__item__scheds__item__info__ttl {
  text-decoration: underline;
}
.overview__content__item__scheds__item__info__desc {
  margin-top: 4px;
  color: #888;
}
.overview__content__item__another {
  margin-top: 16px;
}
.overview__content__item__ttl {
  font-weight: bold;
  margin-bottom: 8px;
}
.overview__content__item__another .overview__content__item__scheds__item__point {
  opacity: 0;
}
@media screen and (max-width:768px) {
  .overview__tab li a {
    font-size: 3vw;
    flex-direction: column;
  }
  .overview__tab li a::after { position: static; }
  .overview__content__item__scheds__item {
    padding-left: 16px;
    position: relative;
    display: block;
  }
  .overview__content__item__scheds__item__time {
    text-align: left;
    margin-bottom: 8px;
  }
  .overview__content__item__scheds__item__point {
    width: 10px;
    height: 100%;
    margin: 0;
    position: absolute;
    top: 4px;
    left: 0;
  }
  .overview__content__item__scheds__item__info {

  }
}


/*------------------------------------------------------------------------------
option
------------------------------------------------------------------------------*/
.option {

}
.option__list {

}
.option__list__item {

}
.option__list__item + .option__list__item {
  margin-top: 16px;
}
.option__list__item__ttl {
  width: 100%;
  height: 56px;
  font-size: 16px;
  font-weight: bold;
  background: #fff;
  border: 1px solid #DBD4CE;
  border-radius: 2px;
  padding: 0 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}
.option__list__item__ttl__txt {

}
.option__list__item__ttl__ic {
  width: 16px;
  height: 16px;
  margin-left: auto;
  flex-shrink: 0;
  position: relative;
}
.option__list__item__ttl__ic span {
  width: 100%;
  height: 2px;
  display: block;
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
}
.option__list__item__ttl__ic span:last-child {
  transform: rotate(90deg);
}
.is-active .option__list__item__ttl__ic span:last-child {
  display: none;
}
.option__list__item__content {
  width: 100%;
  margin-top: 8px;
  background: #fff;
  border-radius: 2px;
  padding: 16px;
  display: none;
}
.option__list__item__content.is-active {
  display: block;
}
.option__list__item__content li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.option__list__item__content li + li {
  border-top: 1px solid #DBD4CE;
  margin-top: 16px;
  padding-top: 16px;
}
.option__list__item__content__ttl {
  width: 240px;
  font-weight: bold;
  flex-shrink: 0;
}
.option__list__item__content__info {

}
.option__list__item__content__info > * + * {
  margin-top: 4px;
}
.option__list__item__content__info__desc,
.option__list__item__content__info__price {
  display: flex;
  align-items: center;
}
.option__list__item__content__info__desc > p,
.option__list__item__content__info__price > p {
  font-weight: bold;
  margin-top: 4px;
  flex-shrink: 0;
}
.option__list__item__content__info__desc > div,
.option__list__item__content__info__price > div {
  display: flex;
  flex-wrap: wrap;
}
.option__list__item__content__info__desc > div > p,
.option__list__item__content__info__price > div > p {
  margin-right: 16px;
  margin-top: 4px;
}
.option__list__item__content__info__desc > div > p > span,
.option__list__item__content__info__price > div > p > span {
  line-height: 24px;
  border: 1px solid #DBD4CE;
  padding: 0 4px;
  margin-right: 4px;
  display: inline-block;
}
@media screen and (max-width:768px) {
  .option__list__item__content { padding: 16px 8px; }
  .option__list__item__content li { display: block; }
  .option__list__item__content__info__desc,
  .option__list__item__content__info__price {
    margin-top: 16px;
    display: block;
  }
}


/*------------------------------------------------------------------------------
information
------------------------------------------------------------------------------*/
.information {

}
.information__content {
  width: 100%;
  border-radius: 2px;
  background: #fff;
  padding: 16px;
}
.information__content__list {

}
.information__content__list__item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.information__content__list__item + .information__content__list__item {
  margin-top: 16px;
}
.information__content__list__item__ttl {
  width: 160px;
  border-radius: 2px;
  padding: 8px;
  text-align: center;
  font-weight: bold;
  margin-right: 8px;
  flex-shrink: 0;
}
.information__content__list__item__body {

}
@media screen and (max-width:768px) {
  .information__content__list__item { display: block; }
  .information__content__list__item__body { margin-top: 8px; }
}


/* from 202105.css */

.base-info {
  /* color: #333; */
  /* background: #f9f9f9;*/
  padding-top: 1px;
}
.base-info__headline {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  padding: 0 16px;
  margin-top: 32px;
}
.base-info__headline__custompage {
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  padding: 0 16px;
  margin-top: 32px;
  color: var(--theme-text-color);
}
.base-info__visual {
  width: 100%;
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.base-info__visual::after {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2);
  position: absolute;
  top: 0;
  left: 0;
}
.base-info__visual img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.base-info__visual h1 {
  width: 100%;
  max-width: 960px;
  font-size: 32px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  position: relative;
  z-index: 1;
}
.base-info__frame {
  width: 100%;
  max-width: 960px;
  background: #fff;
  background: var(--theme-content-section-background-color);
  border-radius: 8px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;
  padding: 32px;
}
.base-info__frame > * + * {
  margin-top: 32px;
}
.base-info__frame__txt {
  font-size: 16px;
}
.base-info__frame__maintxt {
  font-size: 16px;
  border-radius: 4px;
  background: #f9f9f9;
  padding: 16px;
}
.base-info__frame__listBox {
  border-bottom: 1px solid #eee;
}
.base-info__frame__listBox__box {
  padding-bottom: 32px;
}
.base-info__frame__listBox__box__ttl {
  width: 100%;
  border: 1px solid ; /* #333;*/
  border-color: #0094cc;
  border-color: var(--theme-primary-color);
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 16px;
}
.base-info__frame__listBox__box__ttl p {
  width: 48px;
  /* background: #333; */
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.base-info__frame__listBox__box__ttl img {
  width: 32px;
  height: 32px;
}
.base-info__frame__listBox__box__ttl h2 {
  width: 100%;
  font-size: 24px;
  font-weight: bold;
  padding: 4px 8px;
}
.base-info__frame__listBox__box__list {
  padding-left: 24px;
}
.base-info__frame__listBox__box__list li {
  font-size: 18px;
  list-style: disc;
}
.base-info__frame__listBox__box__list li + li {
  margin-top: 8px;
}
.base-info__editor {
  width: 100%;
  max-width: 960px;
  padding: 32px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32px;
  border-radius: 8px;
  background: #fff;
  background: var(--theme-content-section-background-color);
}
.base-info__frame__ttl {
  font-size: 24px;
  font-weight: bold;
  color: var(--theme-subheader-color);
  border-left: 8px solid #333;
  border-left: 8px solid var(--theme-subheader-color);
  padding-left: 8px;
}
.base-info__frame__list {

}
.base-info__frame__list li {
  width: 100%;
  border-bottom: 1px solid #eee;
  padding-top: 16px;
  padding-bottom: 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-info__frame__list li:first-child {
  border-top: 1px solid #eee;
}
.base-info__frame__list li p:first-child {
  width: 160px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  border: 1px solid #ddd;
  padding: 4px;
  margin-right: 16px;
  flex-shrink: 0;
}
.base-info__frame__list li p:last-child {
  font-size: 16px;
}
.base-info__frame__map {
  width: 100%;
  height: 480px;
  background: #eee;
  position: relative;
}
.base-info__frame__map iframe {
  width: 100%;
  height: 100%;
}
.base-info__frame__acBox {

}
.base-info__frame__acttl {
  font-size: 20px;
  font-weight: bold;
  color: var(--theme-subheader-color);
  border-left: 8px solid #333;
  border-left: 8px solid var(--theme-subheader-color);
  padding-left: 8px;
  margin-bottom: 16px;
}
.base-info__frame__ac {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #eee;
}
.base-info__frame__ac + .base-info__frame__ac {
  margin-top: 16px;
}
.base-info__frame__ac__header {
  width: 100%;
  min-height: 48px;
  background: #eee;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 16px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.base-info__frame__ac__header span {
  width: 24px;
  height: 24px;
  background: #fff;
  border-radius: 2px;
  margin-left: auto;
  position: relative;
  flex-shrink: 0;
}
.base-info__frame__ac__header span::before,
.base-info__frame__ac__header span::after {
  content: "";
  width: 12px;
  height: 2px;
  display: block;
  position: absolute;
  top: calc(50% - 1px);
  left: calc(50% - 6px);
}
.base-info__frame__ac__body {
  padding: 16px;
}
.base-info__frame__ac__header.is-open { border-radius: 4px 4px 0 0; }
.base-info__frame__ac__header.is-open span:before { background: #888; }
.base-info__frame__ac__header.is-open span:after { opacity: 0; }
.base-info__frame__ac__header.is-close { border-radius: 4px; }
.base-info__frame__ac__header.is-close span:before { background: #333; }
.base-info__frame__ac__header.is-close span:after { background: #333; transform: rotate(90deg); }
.base-info__frame__ac__body {

}
.base-info__frame__ac__body.is-open {

}
.base-info__frame__ac__body.is-close {
  display: none;
}
.news_list__description {
  margin: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.c-news_list__show__more {
  width: 160px;
  height: 40px;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  background: #0094cc;
  background: var(--theme-primary-color);
  border-radius: 8px;
  margin: 16px auto 0 auto;
  display: block;
  cursor: pointer;
}

@media screen and (max-width:768px) {
  .paddingTop32-sp { padding-top: 32px; }
  .base-info__headline { font-size: 24px; }
  .base-info__headline__custompage { font-size: 24px; }
  .base-info__visual { height: 320px; }
  .base-info__visual h1 { font-size: 20px; padding: 0 16px; }
  .base-info__frame { border: 0; padding: 16px; }
  .base-info__editor { padding: 16px; }
  .base-info__frame__listBox__box__ttl img { width: 32px; height: 32px; }
  .base-info__frame__listBox__box__ttl h2 { font-size: 20px; }
  .base-info__frame__list li { flex-direction: column; align-items: flex-start; }
  .base-info__frame__list li p:first-child {
    width: 100%;
    text-align: left;
    border: 0;
    padding: 0;
    margin-right: 0;
    margin-bottom: 4px;
  }
  .base-info__frame__map { height: 300px; }
}




